// Finnish
export default{
    form: {
        startSurveyButton: "Aloitetaan",
        nextButton: "Seuraava",
        submitAnswerButton: "Lähetä vastaus",
        continueButton: "Jatka",
        finishButton: "Valmis",
        headerPercentageCompletion: 'suoritettu',
        headerQuestionsCompleted: 'kysymystä',
        headerDefaultErrorMessage: 'Hups. Jotain meni pieleen.',
        brandingPoweredBy: 'Tarjoaa',
        brandingPromoWhySurvey: 'Miksi tehdä kysely, kun voit <b>{product}</b>?',
        brandingPromoGetStarted: 'Aloita tänään!',
        submissionPending: "Lomaketta lähetetään. Älä sulje selainikkunaa.",
        submissionCanCloseWindow: "Lomakkeesi on lähetetty onnistuneesti. Voit nyt sulkea ikkunan 👍",
        mediaAttachmentOverlayCloseButton: "Sulje",
        expiredFormHeader: "Kiitos käynnistä!",
        expiredFormMessage: "Näyttää siltä, että tämä kysely on vanhentunut. Ota yhteyttä lomakkeen luojaan ja kerro siitä heille.",
        notPublishedFormHeader: "Lomaketta ei ole julkaistu!",
        notPublishedFormMessage: "Näyttää siltä, että tätä kyselyä ei ole julkaistu. Ota yhteyttä lomakkeen luojaan ja kerro siitä heille.",
        notPublishedFormRetryButton: "Yritä uudelleen",
        errorFormHeader: "Hups!",
        errorFormMessage: "Jotain saattoi mennä pieleen. Yritä myöhemmin uudelleen.",
        errorFormRetryButton: "Yritä uudelleen",
        emptyFormHeader: "Kiitos käynnistä!",
        emptyFormMessage: "Hups! Näyttää siltä, että tämä lomake on tällä hetkellä tyhjä, eikä siinä ole kysymyksiä tai kenttiä täytettäväksi. Pahoittelemme häiriötä. Korjataksesi tämän, ota yhteyttä lomakkeen luojaan ja ilmoita ongelmasta. Kiitos ymmärryksestäsi ja yhteistyöstäsi!",

        restoreFormHeader: "Näyttää siltä, että sinulla on lomake kesken!",
        restoreFormMessage: "Haluatko jatkaa siitä, mihin jäit?",
        restoreFormButton: "Jatka lomaketta",
        restoreFormButtonNew: "Aloita alusta",

        audioPermissionHeader: "Haluatko vastata äänellä?",
        audioPermissionMessage: "Puhuminen tekee vastaamisesta nopeaa ja helppoa.",
        audioPermissionAcceptButton: "Kyllä, haluan",
        audioPermissionDenyButton: "Ei, kiitos",
        audioPermissionErrorHeader: "Mikrofoni ei ole käytettävissä",
        audioPermissionErrorMessage: "Tarkista selaimesi käyttöoikeudet mikrofonin käyttöä varten.",
        audioPermissionErrorButton: "Selvä",
        audioRecorderFailedUpload: "Äänitteen lataaminen epäonnistui",
        silenceDetectorError: "En kuule sinua. Tarkista mikrofonin asetukset. Vihje: Voi olla, että käytät ulkoista mikrofonia.",
        audioRecorderButtonRecord: "Aloita äänitys",
        audioRecorderButtonRecordMore: "Äänitä lisää",
        voiceResponseSelectionMessage: "Valitse, miten haluat vastata...",
        voiceResponseSelectionOrDivider: "tai",
        voiceResponseTextInputPlaceholder: "Kirjoita vastaus",
        voiceResponseTextInputCharacterCounterMinMessage: "Anna vähintään {min} merkkiä",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} merkkiä (vähintään {min} merkkiä)",
        voiceResponseTextInputCharacterValidationMessage: "{count} merkkiä (vähintään {min} merkkiä)",

        // checkbox
        checkboxValidationTooFew: "Sinun on valittava vähintään {min} vaihtoehtoa",
        checkboxValidationTooMany: "Sinun on valittava enintään {max} vaihtoehtoa",
        checkboxNoneOfTheAboveOption: "Ei mikään näistä",
        checkboxInvalidAnswer: "Virheellinen vastaus.",

        // datepicker
        datePickerPlaceholder: "Valitse päiväys",

        // dropdown
        // email
        emailLabel: "Sähköposti",
        emailInvalid:"Virheellinen sähköposti.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Tallenna video",
        fileUploadPluginScreenCast: "Näytön tallennus",
        fileUploadVideoImportFilesDefault: 'Tallenna tai lataa video:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Valitse videotallennusvaihtoehto:',
        fileUploadVideoImportFilesNoCamera: 'Tallenna video painamalla nappia',
        fileUploadVideoImportFilesNoScreenCast: 'Tallenna video painamalla nappia',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Tallennusvaihtoehtoja ei ole saatavilla',

        // matrix
        matrixValidationMessage: "Vastaus on kelvollinen.",
        matrixRow: "Rivi",
        matrixColumn: "Sarake",
        matrixRowRequired: "Rivi {rowTitle} on pakollinen.",
        matrixRadioGroup: "Radioryhmä",
        matrixCheckboxGroup: "Valintaruuturyhmä",
        matrixGroupRequired: "Riville {rowTitle}. {type} {groupTitle} on pakollinen.",
        matrixColumnRequired: "Riville {rowTitle}. Sarake {columnTitle} on pakollinen.",
        matrixColumnInvalid: "Riville {rowTitle}. Sarake {columnTitle} on virheellinen.",
        matrixRequired: "Pakollinen.",
        matrixNumericMustBeNumber: "Täytyy olla numero.",
        matrixNumericMustBeGreaterThenMin: "Täytyy olla suurempi kuin {min}.",
        matrixNumericMustBeLessThenMax: "Täytyy olla pienempi kuin {max}.",
        matrixNumericMustBeInteger: "Täytyy olla kokonaisluku.",
        matrixNumericInvalidNumber: "Virheellinen numero.",

        // name
        nameLabel: "Nimi",
        nameInvalid: "Ilmoita etu- ja sukunimi.",

        // nps
        npsNotLikely: "Erittäin epätodennäköistä",
        npsExtremelyLikely: "Erittäin todennäköistä",

        // numeric input
        numericInputRequired: "Pakollinen.",
        numericInputMustBeNumber: "Täytyy olla numero.",
        numericInputMustBeGreaterThenMin: "Täytyy olla suurempi kuin {min}.",
        numericInputMustBeLessThenMax: "Täytyy olla pienempi kuin {max}.",
        numericInputMustBeInteger: "Täytyy olla kokonaisluku.",
        numericInputInvalidNumber: "Virheellinen numero.",
        numericInputPlaceholder: "Kirjoita numero",

        // phone
        phoneInvalid: "Puhelinnumero on virheellinen.",
        phoneCountrySelectorLabel: 'Maatunnus',
        phoneCountrySelectorError: 'Valitse maa',
        phoneNumberLabel: 'Puhelinnumero',
        phoneExample: 'Esimerkki:',

        // boolean
        booleanYesLabel: "Kyllä",
        booleanNoLabel: "Ei",

        //questionStep
        questionStepAudioQuestionLabel: "Äänikysymys",

        // errors
        invalidPhoneNumber: "{phone} on virheellinen puhelinnumero.",
        invalidEmail: "{email} on virheellinen sähköpostiosoite.",
        questionIsRequired: "Kysymys on pakollinen.",
        answerIsNotValid: "Vastaus ei ole kelvollinen.",
        unfinishedProbingDialogError: "Täytä keskustelu loppuun.",
        cannotResumePartialResponse: "Osittaista vastausta ei voi jatkaa.",
        failedToSubmitForm: "Lomakkeen lähettäminen epäonnistui. Tarkista internet-yhteytesi ja yritä uudelleen.",

         //language picker
         searchLanguage: "Etsi kieltä",
    }
}
